<template>
  <div>
    <div class="header">
      <img src="@/assets/wyscreen-logo.png" height="40" alt="" />
    </div>
    <MDBContainer>
      <video controls autoplay>
        <source src="https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/720/Big_Buck_Bunny_720_10s_20MB.mp4" type="video/mp4">
      </video>

<!--        <MDBCarousel :interval="8000" :items="items" fade dark :indicators="true"/>-->
    </MDBContainer>
  </div>
</template>

<script>
//import { MDBCarousel, MDBContainer } from "mdb-vue-ui-kit";
import { MDBContainer } from "mdb-vue-ui-kit";

export default {
  name: 'App',
  components: {
//    MDBCarousel, MDBContainer
    MDBContainer
  },
  data() {
    return {
      items: [
        {
          src: "https://mdbootstrap.com/img/video/Lines.mp4"
        },
        {
          src: "https://mdbootstrap.com/img/video/animation-intro.mp4"
        },
        {
          src: 'https://www.wyscreen.de/app/mview/v1/e7ae28b8-bb44-4d93-83a6-2ada7d0a207b/1FF64C1C7F8937425F7E3D4100007EBC.png'
        },
        {
          src: 'https://www.wyscreen.de/app/mview/v1/e7ae28b8-bb44-4d93-83a6-2ada7d0a207b/4FBD0DF15D6D1C3DAD37585400006495.png'
        },
        {
          src: 'https://www.wyscreen.de/app/mview/v1/e7ae28b8-bb44-4d93-83a6-2ada7d0a207b/1576EE1B9B6FC0615F43F08D000284BF.png'
        },
        {
          src: 'https://www.wyscreen.de/app/mview/v1/e7ae28b8-bb44-4d93-83a6-2ada7d0a207b/48DE932DBC1A81E292D156C30001BF76.png'
        }
      ]
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
  background-color: #e5e5e5;
  min-height: 100vh;
  height: 100%;
  max-width: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  background-color: #473775;
  color: #fff;
  padding: 10px;
}

.header p {
  padding: 10px;
}

img {
  max-width: 100%;
}

.carousel-control-next, .carousel-control-prev {
  color: black;
  opacity: 0!important;
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: black;
  opacity: 0!important;
}
</style>
